import { Splide } from "@splidejs/splide";

document.addEventListener("DOMContentLoaded", function () {
  const element = document.querySelector(".classic-slider");

  if (element) {
    console.log("Element gefunden:", element);

    if (element && !element.dataset.splideInitialized) {
        element.dataset.splideInitialized = "true";

        const control = element.querySelector(".js-control");
    const playToggle = element.querySelector(".splide__toggle");

    const slidesPerPage = Number(control.dataset.spp)
      ? Number(control.dataset.spp)
      : 1;
    const slidesPerMove = Number(control.dataset.spm)
      ? Number(control.dataset.spm)
      : 1;
    const tabletSlidesPerPage = Number(control.dataset.tspp)
      ? Number(control.dataset.tspp)
      : 1;
    const tabletslidesPerMove = Number(control.dataset.tspm)
      ? Number(control.dataset.tspm)
      : 1;
    const loop = control.dataset.loop === "true" ? "loop" : "slide"
    const autoplay = control.dataset.autoplay === "true" ? true: false
    const interval = 5000
    const splide = new Splide(".splide.point", {
      type: loop,
      autoplay: autoplay,
      interval: interval,
      perPage: slidesPerPage,
      perMove: slidesPerMove,
      gap: 20,
      mediaQuery: "max",
      pagination: true,
      paginationKeyboard: true,
      arrows: true,
      breakpoints: {
        1200: {
          padding: "1.2rem",
        },
        990: {
          perPage: tabletSlidesPerPage,
          perMove: tabletslidesPerMove,
          padding: "1rem",
        },
        576: {
          perPage: 1,
          perMove: 1,
          padding: "1rem",
        },
      },
    });
    splide.mount();
    }
  } else {
    console.log('Kein Element mit der Klasse "classic-slider" gefunden.');
  }
});
